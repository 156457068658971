<template>
  <router-link to="/index" class="logo-wrapper">
    <img src="../../../assets/logo.png" width="30" height="30" />
    <div class="logo-title verticalMiddle">普达运营系统</div>
  </router-link>
</template>

<script>
export default {
  name: 'logo',
  data() {
    return {};
  }
};
</script>
<style scoped></style>
