<template>
  <a-dropdown class="navUser-wrppaer">
    <div>
      <!-- <img src="../../../assets/nav/user.gif" class="userImg" alt="" /> -->
      <span class="userTitle">{{ accountInfo.name }}</span>
      <a-icon type="caret-down" style="margin-left: 5px" />
    </div>

    <a-menu slot="overlay">
      <!-- <a-menu-item>
        <router-link to="/userInfo/hide-userInfo">个人中心</router-link>
      </a-menu-item> -->
      <!-- <a-menu-divider /> -->
      <a-menu-item>
        <span @click.self="logout">退出登录 </span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex';
import { sfxtLogOut } from '@/api/user.js';
export default {
  name: 'navUser',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      accountInfo: state => state.user.accountInfo
    })
  },
  methods: {
    logout() {
      sfxtLogOut({}).then(res => {
        if (res.code == 200) {
          this.$store.dispatch('user/logout');
          location.reload();
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.userImg {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: inline-block;
}
.userTitle {
  margin-left: 8px;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
