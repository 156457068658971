import request from '@/utils/request';

export function sysAuthorityList(data) {
  return request.post('/sfht/sysAuthority/list', data);
}

// export function sysPostDel(data) {
//   return request.post('/sfxt/sysPosts/del?ids='+data.ids);
// }

export function authorityList(data) {
  return request.post('/sfht/authorityList', data);
}

export function sysAuthoritySaveOrUpdate(data) {
  return request.post('/sfht/sysAuthority/saveOrUpdate', data);
}

export function sysRoleDel(data) {
  return request.post('/sfht/sysRole/del?ids=' + data.ids);
}

export function sysRolePagelist(data) {
  return request.post('/sfht/sysRole/pagelist', data);
}

export function sysRoleSaveOrUpdate(data) {
  return request.post('/sfht/sysRole/saveOrUpdate', data);
}

export function sysRolePermissionAssignment(data) {
  return request.post('/sfht/sysRole/permissionAssignment', data);
}
