import request from '@/utils/request';

export function login(data) {
  return request.post('/sfht/login', data);
}

export function loginCode(data) {
  return request.post('/sfht/authCode?phone=' + data.phone);
}

export function sfxtLogOut(data) {
  return request.post('/sfht/logOut', data);
}

export function enterpriseInformation(data) {
  return request.post('/sfht/registry/enterpriseInformation', data);
}

export function sfRegionList(data) {
  return request.post('/sfht/sfRegion/list', data);
}

export function registryUpdateEnterpriseInformation(data) {
  return request.post('/sfht/registry/updateEnterpriseInformation', data);
}
