const objMixin = {
  data() {
    return {
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      sizeOptions: ['5', '10', '20', '40', '50', '80', '100', '999999']
    };
  },
  created() {},
  filters: {
    filterDateMonth(val) {
      if (!val) {
        return '-';
      }
      let date = new Date(val);
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      return date.getFullYear() + '-' + month;
    },
    filterDateTime(val) {
      if (val) {
        let date = new Date(val);
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = '0' + month;
        }
        let day = date.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        let hours = date.getHours();
        if (hours < 10) {
          hours = '0' + hours;
        }
        let minutes = date.getMinutes();
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        let seconds = date.getSeconds();
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        return date.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      } else {
        return '-';
      }
    },
    filterAddress(record, address = 'address') {
      let res = '';
      if (record.affiliationProvince) {
        res += record.affiliationProvince;
      }
      if (record.affiliationCity) {
        res += record.affiliationCity;
      }
      if (record.affiliationDistrict) {
        res += record.affiliationDistrict;
      }
      if (record[address]) {
        res += record[address];
      }
      if (res) {
        return res;
      } else {
        return '-';
      }
    },
    filterDate(val) {
      if (val) {
        let date = new Date(val);
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = '0' + month;
        }
        let day = date.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        return date.getFullYear() + '-' + month + '-' + day;
      } else {
        return '-';
      }
    }
  },
  methods: {
    getTime() {
      let date = new Date();
      let hours = date.getHours();
      if (hours < 10) {
        hours = '0' + hours;
      }
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return hours + ':' + minutes + ':' + seconds;
    },
    resetForm(form) {
      for (let key in form) {
        if (!form[key] && form[key] !== 0) {
          form[key] = undefined;
        } else {
          if (typeof form[key] == 'string') {
            form[key] = form[key].trim();
          }
        }
      }
    },
    initInputData(row, index, key, listKey) {
      if (!row[key] || row[key] == '0') {
        listKey[index][key] = '';
      }
    },
    blurInputData(row, index, key, listKey) {
      if (!row[key]) {
        listKey[index][key] = 0;
      }
    },
    initInputObjectData(form, key) {
      if (!form[key] || form[key] == '0') {
        form[key] = '';
      }
    },
    blurInputObjectData(form, key) {
      if (!form[key]) {
        form[key] = '0';
      }
    },
    getDates(year) {
      let dates = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year % 400 == 0 || (year % 4 == 0 && year % 100 != 0)) {
        dates[1] = 29;
      }
      return dates;
    },
    getCurrentMonth() {
      let date = new Date();
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;

      date.setMonth(mm);
      let yy2 = date.getFullYear();
      let dates = this.getDates();
      let dd2 = dates[mm - 1];
      if (mm < 10) {
        mm = '0' + mm;
      }

      return [yy + '-' + mm + '-01', yy2 + '-' + mm + '-' + dd2];
    },
    regexNumber(val) {
      if (!val) {
        return '0';
      }
      let res = val + '';
      res = res.replaceAll(/\D/gi, '');
      if (res) {
        let data = res.split('');
        while (data[0] == '0' && data.length > 1) {
          data.splice(0, 1);
        }
        if (Array.isArray(data)) {
          res = data.join('');
        }
        return res;
      }
      return '';
    },
    numberChange(val, form, key) {
      if (!val) {
        form[key] = '';
        return;
      }
      let res = val + '';
      res = res.replaceAll(/\D/gi, '');
      if (res) {
        let data = res.split('');
        while (data[0] == '0' && data.length > 1) {
          data.splice(0, 1);
        }
        if (Array.isArray(data)) {
          res = data.join('');
        }
        form[key] = res;
        return;
      }
      form[key] = '';
    },
    filterDate(val) {
      let date = new Date(val);
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      return date.getFullYear() + '-' + month + '-' + day;
    },
    filterTime(val) {
      let date = new Date(val);
      let hours = date.getHours();
      if (hours < 10) {
        hours = '0' + hours;
      }
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return hours + ':' + minutes + ':' + seconds;
    },
    initSubmit(form) {
      for (let key in form) {
        if (!form[key] && form[key] != 0) {
          delete form[key];
        }
      }
    },
    filterDateTime() {
      let date = new Date();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      let hours = date.getHours();
      if (hours < 10) {
        hours = '0' + hours;
      }
      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return date.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
  }
};

export default objMixin;
