import { baseRoute, asyncRoutes } from '@/router';
import { authorityList } from '@/api/limit.js';
const state = {
  routes: [],
  menuList: []
};

const mutations = {
  SET_ROUTE(state, route) {
    state.routes = route;
  },
  RESET_ROUTE(state) {
    state.routes = [];
  }
};

const actions = {
  getRoute({ commit }, role) {
    return authorityList().then(res => {
      let accessedRoutes = asyncRoutes;
      accessedRoutes = getMenuListTree(asyncRoutes, res.data);
      accessedRoutes.push(...baseRoute);
      commit('SET_ROUTE', accessedRoutes);
      return accessedRoutes;
    });
  }
  //   //仅测试用，生产请去掉
  //   async changeRole({ commit, dispatch }, role) {
  //     await dispatch('user/login', role);
  //     resetRouter();
  //     const accessedRoutes = await dispatch('getRoute', role);
  //     router.addRoutes(accessedRoutes);
  //     await dispatch('tagsView/clearTag', null, { root: true });
  //   }
};

export function getMenuListTree(routes, menuList) {
  let menuStrList = menuList.map(item => item.authorityCode);
  menuTree(menuStrList, routes);
  return routes;
}

function menuTree(list, routes) {
  if (routes) {
    for (let i = routes.length - 1; i >= 0; i--) {
      if (routes[i].meta && !routes[i].meta.base && !list.includes(routes[i].meta.role)) {
        routes.splice(i, 1);
      } else {
        if (routes[i].change == 'enterprise') {
          if (!list.includes('HT:enterprise')) {
            routes[i].path = '/custom';
            routes[i].redirect = '/custom/index';
          }
        }
        if (routes[i].change == 'root') {
          if (!list.includes('HT:enterprise')) {
            routes[i].redirect = '/custom';
          }
        }
        menuTree(list, routes[i].children);
      }
    }
  } else {
    return;
  }
}

export function filterAsyncRoute(routes, role) {
  let arr = [];
  routes.forEach(item => {
    const temp = { ...item };
    if (hasChildren(temp, role)) {
      if (temp.children) {
        temp.children = filterAsyncRoute(temp.children, role);
      }
      arr.push(temp);
    }
  });
  return arr;
}

export function hasChildren(route, role) {
  let roleIds = role.split(',');
  if (route.meta && route.meta.role) {
    return roleIds.includes(String(route.meta.role));
  } else {
    return true;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
